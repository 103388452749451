import React from 'react'

import { BrowserRouter as Router } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import 'antd/dist/reset.css'
import './App.css'
import { AuthProvider } from './modules'
import enTranslation from './locales/en.json'
import { ConfigProvider } from 'antd'
import { AppRouter } from './routers/AppRouter'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: enTranslation,
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Montserrat',
          },
        }}
      >
        <Router>
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </Router>
      </ConfigProvider>
    </>
  )
}

export default App
